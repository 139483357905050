export const changeLang = (langNew, lang, setLang) => {
    if(langNew === lang){ return }

    setLang(langNew)
    localStorage.setItem("lang", langNew);

    let urlNew = new URL(window.location)
    urlNew = urlNew.pathname
    let revLang
    if(langNew === 'en'){revLang = 'ru'}else{revLang = 'en'}
    if(urlNew.indexOf('/' + revLang) + 1) {
        urlNew = urlNew.replace('/' + revLang, '/' + langNew);
    }else{
        if(urlNew[urlNew.length - 1] === '/'){
            urlNew = urlNew + langNew
        }else{
            urlNew = urlNew + '/' + langNew
        }
    }
    window.history.pushState(null, null, urlNew);
}
import React, {useContext, useEffect, useRef, useState} from 'react';
import {AuthContext} from '../context/Context.js';
import {pg_Team1, pg_Team2, pg_Team3} from "../context/pg_Team";
import {basicText} from "../context/BasicInterfaceText"
import mapAnim from "../img/about/map.gif";
import mapNo from "../img/about/map_none.png";
import icoMail from "../img/about/sr_soc_mail.png";
import icoWa from "../img/about/sr_soc_wa.png";
import icoTg from "../img/about/sr_soc_tg.png";
import sr from "../img/about/blue_line_sr.png";
import ico1 from "../img/about/men_icon_edu.png";
import ico2 from "../img/about/men_icon_data.png";
import ico3 from "../img/about/men_icon_exp.png";

const TeamPg = () => {
    // states and variables
    const {lang, isLandscape, isLaptop, isPad, appFirstLoad, setAppFirstLoad, pgTeamFirstLoad, setPgTeamFirstLoad} = useContext(AuthContext)
    const [pgLoaded, setPgLoaded] = useState(false)
    const [dirDrop, setDirDrop] = useState(false)

    // refs
    const h1 = useRef()
    const h2 = useRef()

    // loading
    useEffect(() => {
        if(pgLoaded){
            return
        }else{
            setPgLoaded(true)
        }

        let descStartTimeOffset = 2500
        let mobStartTimeOffset = 1900
        if(!appFirstLoad){
            descStartTimeOffset = 600
            mobStartTimeOffset = 600
        }
        if(isLandscape){
            let v1 = '3vw 0 0 3.5vw'
            let v2 = '1.6vw'
            if(isLaptop){
                v1 = '2.5vw 0 0 3.5vw'
                v2 = '1.5vw'
            }

            // map animation when scrolling
            if(!pgTeamFirstLoad){
                setPgTeamFirstLoad(true)
                const screenVW = window.innerWidth / 25
                function mapAnimation() {
                    if(document.getElementById('id-map')){
                        let el = document.getElementById('id-map')
                        if(el){
                            let offset =  window.scrollY / screenVW -1
                            el.style.top = offset + 'vw'
                        }
                    }
                }
                document.addEventListener("scroll", mapAnimation)
            }

            setTimeout(function () {
                if(h1){
                    if(h1.current){
                        h1.current.style.opacity = '1'
                    }
                }
                if(document.querySelector('.page-team-map-img')){
                    document.querySelector('.page-team-map-img').src = mapAnim
                }
                setTimeout(function () {
                    if(h2){
                        if(h2.current){
                            h2.current.style.opacity = '1'
                        }
                    }
                },300)
                setTimeout(function () {
                    if(document.querySelector('.page-team-director-top')){
                        document.querySelector('.page-team-director-top').style.width = '100%'
                    }
                },1600)
                setTimeout(function () {
                    if(document.querySelector('.page-team-director-top')){
                        document.querySelector('.page-team-director-top').style.right = '0'
                    }
                },2100)
                setTimeout(function () {
                    if(document.querySelector('.page-team-director-top')){
                        document.querySelector('.page-team-director-top').style.height = '21vw'
                        document.querySelector('.page-team-director-top').style.backgroundColor = '#333'
                        document.querySelector('.page-team-director-top').style.boxShadow = '0 1vw 2vw 0 rgba(0,0,0,.3)'
                    }
                },2700)
                setTimeout(function () {
                    if(document.querySelector('.page-team-director-top-tab')){
                        document.querySelector('.page-team-director-top-tab').style.width = '6.5vw'
                    }
                    if(document.querySelector('.page-team-map-wrp')){
                        document.querySelector('.page-team-map-wrp').style.opacity = '1'
                    }
                },3400)
                setTimeout(function () {
                    if(document.querySelector('.page-team-director-top')){
                        document.querySelector('.page-team-director-top').style.padding = v1
                    }
                },3700)
                setTimeout(function () {
                    if(document.querySelector('.page-team-director-top h3')){
                        document.querySelector('.page-team-director-top h3').style.marginLeft = '0'
                        document.querySelector('.page-team-director-top h3').style.width = '50vw'
                    }
                    if(document.querySelector('.page-team-director-top-fon')){
                        document.querySelector('.page-team-director-top-fon').style.display = 'block'
                    }
                },3800)
                setTimeout(function () {
                    if(document.querySelector('.page-team-director-top-subtit')){
                        document.querySelector('.page-team-director-top-subtit').style.marginLeft = '0'
                        document.querySelector('.page-team-director-top-subtit').style.width = '50vw'
                    }
                    if(document.querySelector('.page-team-director-top-fon')){
                        document.querySelector('.page-team-director-top-fon').style.opacity = '1'
                    }
                },4100)
                setTimeout(function () {
                    if(document.querySelector('.dirlink1')){
                        document.querySelector('.dirlink1').style.marginLeft = '0'
                        document.querySelector('.dirlink1').style.maxWidth = '50vw'
                    }
                },4300)
                setTimeout(function () {
                    document.querySelectorAll('.page-team-bottom .men').forEach((men, i) => {
                        setTimeout(function () {
                            if(men){
                                men.children[0].style.height = "3vw"
                                men.children[1].style.height = "20vw"
                                men.children[1].children[0].style.height = "20vw"
                            }
                        }, 300*i)
                        setTimeout(function () {
                            if(men){
                                men.children[1].children[1].style.maxHeight = "10vw"
                                men.children[1].children[1].style.paddingTop = "1.4vw"
                                men.children[1].children[1].style.paddingBottom = v2
                            }
                        }, 300*i+500)
                    })
                },4400)
                setTimeout(function () {
                    if(document.querySelector('.dirlink2')){
                        document.querySelector('.dirlink2').style.marginLeft = '0'
                        document.querySelector('.dirlink2').style.maxWidth = '50vw'
                    }
                },4600)
                setTimeout(function () {
                    if(document.querySelector('.dirlink3')){
                        document.querySelector('.dirlink3').style.marginLeft = '0'
                        document.querySelector('.dirlink3').style.maxWidth = '50vw'
                    }
                    if(document.querySelector('.page-team-bottom')){
                        document.querySelector('.page-team-bottom').style.marginBottom = '8vw'
                    }
                },4900)
            },descStartTimeOffset)
        }else{
            setTimeout(function () {
                if(h1){
                    if(h1.current){
                        h1.current.style.opacity = '1'
                    }
                }
                setTimeout(function () {
                    if(h2){
                        if(h2.current){
                            h2.current.style.opacity = '1'
                        }
                    }
                },300)
                setTimeout(function () {
                    if(document.querySelector('.page-team-mobfon')){
                        document.querySelector('.page-team-mobfon').style.opacity = '1'
                    }
                },600)
                setTimeout(function () {
                    document.querySelectorAll('.page-team-bottom .men').forEach((men, i) => {
                        setTimeout(function () {
                            if(men){
                                men.style.maxHeight = "300vw"
                                men.style.marginBottom = "6vw"
                            }
                        }, 300*i)
                    })
                },1000)
            },mobStartTimeOffset)
        }

        setAppFirstLoad(false)
    }, [isLandscape, isLaptop, isPad, appFirstLoad, setAppFirstLoad, pgLoaded, setPgLoaded, pgTeamFirstLoad, setPgTeamFirstLoad])

    return (
        <div className='page page-team'>
            <h1 ref={h1}><span>{pg_Team1[0][lang]}</span> {pg_Team1[1][lang]}</h1>
            {isLandscape ?
                <div className="page-team-top">
                <h2 ref={h2}>{pg_Team1[2][lang]}</h2>
                <div id="id-map" className="page-team-map">
                    <img className="page-team-map-img" src={mapNo} alt=""/>
                    <div className="page-team-map-wrp">
                        {pg_Team3.map((city) => (
                            <div className={`page-team-map-point ${city.main ? "maincity" : ""}`} key={city.id}
                                 style={{left: `${city.left}vw`, top: `${city.top}vw`}}
                            >
                                <div className="page-team-map-point-circle"></div>
                                <div className={`page-team-map-point-details ${city.mirror ? "left" : ""}`}>
                                    <p>{city[`city_${lang}`]}</p>
                                    {city.who.map((who) => {
                                        if(who !== 99 && who !== "99"){
                                            return(
                                                <div onClick={() => {
                                                    document.getElementById(`men-id${who}`).scrollIntoView();
                                                }} key={who} className="page-team-map-point-details-link">
                                                    {pg_Team2[who][`name_${lang}`]}
                                                </div>
                                            )
                                        }else{
                                            return(
                                                <div onClick={() => {
                                                    document.getElementById(`men-id${who}`).scrollIntoView();
                                                }} key={who} className="page-team-map-point-details-link">
                                                    {pg_Team1[3][`${lang}`]}
                                                </div>
                                            )
                                        }
                                    })}
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
                <div className={`page-team-director ${dirDrop ? "page-team-director-active" : ""}`}>
                    <div id="men-id99" className="page-team-director-empty"> </div>
                    <div className="page-team-director-top">
                        <div className="page-team-director-top-tab" onClick={() => setDirDrop(true)} >
                            <img src={sr} alt=""/>
                            <p>{pg_Team1[6][lang]}</p>
                            <p>{pg_Team1[7][lang]}</p>
                            <p>▼</p>
                        </div>
                        <h3>{pg_Team1[3][lang]}</h3>
                        <p className="page-team-director-top-subtit">{pg_Team1[4][lang]}</p>
                        <a className="dirlink1" href={"mailto:" + basicText[9]['data']}>
                            <img src={icoMail} alt=""/>
                            <span>{basicText[9]['data']}</span>
                        </a>
                        <a className="dirlink2" href={"https://wa.me/+" + basicText[6]['data']} target="_blank" rel="noreferrer" >
                            <img src={icoWa} alt=""/>
                            <span>{"+"+basicText[6]['data']}</span>
                        </a>
                        <a className="dirlink3" href={"https://t.me/" + basicText[7]['data']} target="_blank" rel="noreferrer" >
                            <img src={icoTg} alt=""/>
                            <span>{"@"+basicText[7]['data']}</span>
                        </a>
                        <div className="page-team-director-top-fon"> </div>
                    </div>
                    <div className="page-team-director-drop">
                        <div>
                            <div className="page-team-director-drop-line">
                                <img src={ico1} alt=""/>
                                <h5>{pg_Team1[8][lang]}</h5>
                            </div>
                            <hr/>
                            <div className="page-team-director-drop-line">
                                <strong className="point">•</strong>
                                <p>{pg_Team1[11][lang]}</p>
                            </div>
                            <div className="page-team-director-drop-line">
                                <strong></strong>
                                <span>{pg_Team1[12][lang]}</span>
                            </div>
                            <div className="page-team-director-drop-line">
                                <strong></strong>
                                <span>2004 - 2009</span>
                            </div>
                            <hr/>
                            <div className="page-team-director-drop-line">
                                <strong className="point">•</strong>
                                <p>{pg_Team1[13][lang]}</p>
                            </div>
                            <div className="page-team-director-drop-line">
                                <strong></strong>
                                <span>{pg_Team1[14][lang]}</span>
                            </div>
                            <div className="page-team-director-drop-line">
                                <strong></strong>
                                <span>1997 - 2002</span>
                            </div>
                            <hr/><hr/>
                            <div className="page-team-director-drop-line">
                                <img src={ico2} alt=""/>
                                <h5>{pg_Team1[9][lang]}</h5>
                            </div>
                            <hr/>
                            <div className="page-team-director-drop-line">
                                <strong></strong>
                                <span>{pg_Team1[15][lang]}</span>
                            </div>
                            <div className="page-team-director-drop-line">
                                <strong></strong>
                                <span>{pg_Team1[16][lang]}</span>
                            </div>
                            <div className="page-team-director-drop-line">
                                <strong></strong>
                                <span>{pg_Team1[17][lang]}</span>
                            </div>
                        </div>
                        <div>
                            <div className="page-team-director-drop-line">
                                <img src={ico3} alt=""/>
                                <h5>{pg_Team1[10][lang]}</h5>
                            </div>
                            <hr/>
                            <div className="page-team-director-drop-line">
                                <strong></strong>
                                <div className="page-team-director-drop-line-skill">
                                    <p>{pg_Team1[18][lang]}</p>
                                    <div className="men-drop-line-years lite year5">
                                        <div></div><div></div><div></div><div></div><div></div><div></div><div></div>
                                    </div>
                                </div>
                            </div>
                            <hr/>
                            <div className="page-team-director-drop-line">
                                <strong></strong>
                                <div className="page-team-director-drop-line-skill">
                                    <p>{pg_Team1[19][lang]}</p>
                                    <div className="men-drop-line-years lite year4">
                                        <div></div><div></div><div></div><div></div><div></div><div></div><div></div>
                                    </div>
                                </div>
                            </div>
                            <div className="page-team-director-drop-line">
                                <strong></strong>
                                <div className="page-team-director-drop-line-skill">
                                    <p>{pg_Team1[20][lang]}</p>
                                    <div className="men-drop-line-years lite year6">
                                        <div></div><div></div><div></div><div></div><div></div><div></div><div></div>
                                    </div>
                                </div>
                            </div>
                            <div className="page-team-director-drop-line">
                                <strong></strong>
                                <div className="page-team-director-drop-line-skill">
                                    <p>{pg_Team1[21][lang]}</p>
                                    <div className="men-drop-line-years lite year7">
                                        <div></div><div></div><div></div><div></div><div></div><div></div><div></div>
                                    </div>
                                </div>
                            </div>
                            <hr/>
                            <div className="page-team-director-drop-line">
                                <strong></strong>
                                <div className="page-team-director-drop-line-skill">
                                    <p>{pg_Team1[22][lang]}</p>
                                    <div className="men-drop-line-years lite year7">
                                        <div></div><div></div><div></div><div></div><div></div><div></div><div></div>
                                    </div>
                                </div>
                            </div>
                            <div className="page-team-director-drop-line">
                                <strong></strong>
                                <div className="page-team-director-drop-line-skill">
                                    <span>{pg_Team1[23][lang]}</span>
                                    <div className="men-drop-line-years year4">
                                        <div></div><div></div><div></div><div></div><div></div><div></div><div></div>
                                    </div>
                                </div>
                            </div>
                            <div className="page-team-director-drop-line">
                                <strong></strong>
                                <div className="page-team-director-drop-line-skill">
                                    <span>{pg_Team1[24][lang]}</span>
                                    <div className="men-drop-line-years year2">
                                        <div></div><div></div><div></div><div></div><div></div><div></div><div></div>
                                    </div>
                                </div>
                            </div>
                            <div className="page-team-director-drop-line">
                                <strong></strong>
                                <div className="page-team-director-drop-line-skill">
                                    <span>{pg_Team1[25][lang]}</span>
                                    <div className="men-drop-line-years year5">
                                        <div></div><div></div><div></div><div></div><div></div><div></div><div></div>
                                    </div>
                                </div>
                            </div>
                            <div className="page-team-director-drop-line">
                                <strong></strong>
                                <div className="page-team-director-drop-line-skill">
                                    <span>{pg_Team1[26][lang]}</span>
                                    <div className="men-drop-line-years year3">
                                        <div></div><div></div><div></div><div></div><div></div><div></div><div></div>
                                    </div>
                                </div>
                            </div>
                            <div className="page-team-director-drop-line">
                                <strong></strong>
                                <div className="page-team-director-drop-line-skill">
                                    <span>{pg_Team1[27][lang]}</span>
                                    <div className="men-drop-line-years year4">
                                        <div></div><div></div><div></div><div></div><div></div><div></div><div></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="page-team-director-close" onClick={() => setDirDrop(false)}>{pg_Team1[5][lang]}</div>
                </div>
            </div>
            :
                <div>
                    <h2 ref={h2}>{pg_Team1[2][lang]}</h2>
                    <div className="page-team-mobfon"> </div>
                </div>
            }
            <div className="page-team-bottom">
                {!isLandscape ?
                    <div className="men" id="men-id99">
                    <div className="men-top">
                        <img src="/img/about/team_sr.jpg" alt=""/>
                        <div className="men-top-txt">
                            <h4>{pg_Team1[3][lang]}</h4>
                            <p>{pg_Team1[4][lang]}</p>
                        </div>
                    </div>
                    <div className="men-drop">
                        <div className="men-drop-line">
                            <p className="men-drop-line-name">{pg_Team1[18][lang]}</p>
                            <div className="men-drop-line-years year5">
                                <div></div><div></div><div></div><div></div><div></div><div></div><div></div>
                            </div>
                        </div>
                        <div className="men-drop-line">
                            <p className="men-drop-line-name">{pg_Team1[19][lang]}</p>
                            <div className="men-drop-line-years year4">
                                <div></div><div></div><div></div><div></div><div></div><div></div><div></div>
                            </div>
                        </div>
                        <div className="men-drop-line">
                            <p className="men-drop-line-name">{pg_Team1[20][lang]}</p>
                            <div className="men-drop-line-years year6">
                                <div></div><div></div><div></div><div></div><div></div><div></div><div></div>
                            </div>
                        </div>
                        <div className="men-drop-line">
                            <p className="men-drop-line-name">{pg_Team1[21][lang]}</p>
                            <div className="men-drop-line-years year7">
                                <div></div><div></div><div></div><div></div><div></div><div></div><div></div>
                            </div>
                        </div>
                        <div className="men-drop-line">
                            <p className="men-drop-line-name">{pg_Team1[22][lang]}</p>
                            <div className="men-drop-line-years year7">
                                <div></div><div></div><div></div><div></div><div></div><div></div><div></div>
                            </div>
                        </div>
                        <p className="edu">{pg_Team1[11][lang]}</p>
                        <span className="edu-span">{pg_Team1[12][lang]}</span>
                        <span className="edu-span">2004 - 2009</span>
                        <p className="edu">{pg_Team1[13][lang]}</p>
                        <span className="edu-span">{pg_Team1[14][lang]}</span>
                        <span className="edu-span">1997 - 2002</span>
                        <hr/>
                        <a href={"mailto:" + basicText[9]['data']}>
                            <img src={icoMail} alt=""/>
                            <span>{basicText[9]['data']}</span>
                        </a>
                        <a href={"https://wa.me/+" + basicText[6]['data']} target="_blank" rel="noreferrer">
                            <img src={icoWa} alt=""/>
                            <span>{"+"+basicText[6]['data']}</span>
                        </a>
                        <a href={"https://t.me/" + basicText[7]['data']} target="_blank" rel="noreferrer">
                            <img src={icoTg} alt=""/>
                            <span>{"@"+basicText[7]['data']}</span>
                        </a>
                    </div>
                </div>
                : ''}
                {pg_Team2.map((men) => (
                    <div className="men" key={men.id} id={`men-id${men.id}`}>
                        <div className="men-empty"> </div>
                        <div className="men-top">
                            <img src={`/img/about/${men['photo']}`} alt=""/>
                            <div className="men-top-txt">
                                <h4>{men[`name_${lang}`]}</h4>
                                <p>{men[`role_${lang}`]}</p>
                            </div>
                        </div>
                        <div className="men-drop">
                            <div className="men-drop-line">
                                <p className="men-drop-line-name">{men[`skill1_${lang}`]}</p>
                                <div className={`men-drop-line-years year${men['skill1']}`}>
                                    <div></div><div></div><div></div><div></div><div></div><div></div><div></div>
                                </div>
                            </div>
                            <div className="men-drop-line">
                                <p className="men-drop-line-name">{men[`skill2_${lang}`]}</p>
                                <div className={`men-drop-line-years year${men['skill2']}`}>
                                    <div></div><div></div><div></div><div></div><div></div><div></div><div></div>
                                </div>
                            </div>
                            <div className="men-drop-line">
                                <p className="men-drop-line-name">{men[`skill3_${lang}`]}</p>
                                <div className={`men-drop-line-years year${men['skill3']}`}>
                                    <div></div><div></div><div></div><div></div><div></div><div></div><div></div>
                                </div>
                            </div>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default TeamPg;
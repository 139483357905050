export const pg_MainText = [
    {   id: 0,
        en: "5 reasons to choose us:",
        ru: "5 причин выбрать нас:",
    },
    {   id: 1,
        en: "Range of services help to forget about searching for masters for different types of tasks.",
        ru: "Спектр услуг позволяет забыть о поиске отдельных мастеров для разных видов задач.",
    },
    {   id: 2,
        en: "When job is done, we don't disappear! Support, training and management if it necessary.",
        ru: "Сдав проект мы не исчезаем! Техподдержка, обучение и ведение проекта если нужно.",
    },
    {   id: 3,
        en: "Our team member's experience in IT industry is vast and ranges from 9 to 26 years.",
        ru: "Опыт членов нашей команды в IT-индустрии находится в диапазоне от 9 до 26 лет.",
    },
    {   id: 4,
        en: "Our prices are open and fixed. Calculate a cost of your order in a couple of minutes.",
        ru: "Наши цены открыты и понятны. Посчитайте стоимость вашего заказа за пару минут.",
    },
    {   id: 5,
        en: "For startups and who do not want to delve into IT sector, we offer special conditions.",
        ru: "Для стартапов и тех кто не хочет вникать в IT сектор - предложем особые условия.",
    },
    {   id: 6,
        en: "What we can do",
        ru: "Что мы умеем",
    },
    {   id: 7,
        en: "Our projects",
        ru: "Наши проекты",
    },
    {   id: 8,
        en: "More about us",
        ru: "Подробнее о нас",
    },
    {   id: 9,
        en: "Calculator",
        ru: "Калькулятор",
    },
    {   id: 10,
        en: "Partnership terms",
        ru: "Условия партнёрства",
    },
]



































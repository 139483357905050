export const pg_Team1 = [
    {   id: 0,
        en: "Together",
        ru: "Вместе",
    },
    {   id: 1,
        en: "we are power",
        ru: "мы сила",
    },
    {   id: 2,
        en: "Our team has been formed over many years and has solved various, complex problems. The experience of team members in the IT industry ranges from 9 to 26 years, each of whom is a professional in his field. You do not have to contact each of the specialists during the work process, as one of us will be the responsible contact person. You just need to say what you want and your wishes will come true. We will become your digital wizards - you will love it :)",
        ru: "Наша команда формировалась на протяжении многих лет и решала разнообразные, сложные задачи. Опыт членов команды в IT индустрии составляет от 9 до 26 лет, каждый из которых является профессионалом своего дела. Вам не обязательно контактировать с каждым из специалистов в процессе работы, тк один из нас будет ответственным контактным лицо. Вам просто нужно сказать, что вы хотите и ваши желания сбудутся. Мы станем вашими цифровыми волшебниками - вам понравится :)",
    },


    {   id: 3,
        en: "Samborsky Roman",
        ru: "Самборский Роман",
    },
    {   id: 4,
        en: "TEAM LEAD & FULL-STACK WEB DEVELOPER",
        ru: "ТИМЛИД и ФУЛСТЭК ВЕБ РАЗРАБОТЧИК",
    },
    {   id: 5,
        en: "Collapse details",
        ru: "Свернуть подробности",
    },
    {   id: 6,
        en: "more",
        ru: "смотреть",
    },
    {   id: 7,
        en: "details",
        ru: "подробнее",
    },


    {   id: 8,
        en: "Education",
        ru: "Образование",
    },
    {   id: 9,
        en: "Details",
        ru: "Детали",
    },
    {   id: 10,
        en: "Experience in years",
        ru: "Опыт в годах",
    },


    {   id: 11,
        en: "Novosibirsk State University of Civil Engineering and Architecture",
        ru: "Новосибирский Государственный Архитектурно-Строительный Университет",
    },
    {   id: 12,
        en: "Information systems and technology department",
        ru: "Фак-т: «Информационные Системы и Технологии»",
    },
    {   id: 13,
        en: "Art school «North Spring»",
        ru: "Художественная школа «Весна»",
    },
    {   id: 14,
        en: "Red diploma of art educations",
        ru: "Диплом о художественном образовании",
    },
    {   id: 15,
        en: "Текущий адрес: Аланья, Турция",
        ru: "Current address: Alanya, Turkey",
    },
    {   id: 16,
        en: "English level: Intermediate",
        ru: "Уровень английского: Intermediate",
    },
    {   id: 17,
        en: "Date of Birth: June 23, 1987",
        ru: "Дата рождения: 23.06.87",
    },


    {   id: 18,
        en: "Team lead (GIT, CRM)",
        ru: "Тимлид (GIT, CRM)",
    },
    {   id: 19,
        en: "Backend (NodeJS, PHP)",
        ru: "Бэкенд (NodeJS, PHP)",
    },
    {   id: 20,
        en: "Frontend (React, JQ)",
        ru: "Фронтенд (React, JQ)",
    },
    {   id: 21,
        en: "Layout (HTML, LESS)",
        ru: "Верстка (HTML, LESS)",
    },
    {   id: 22,
        en: "Design (UX, UI)",
        ru: "Дизайн (UX, UI)",
    },
    {   id: 23,
        en: "Branding",
        ru: "Брендинг",
    },
    {   id: 24,
        en: "Polygraphy",
        ru: "Полиграфия",
    },
    {   id: 25,
        en: "Raster illustrations",
        ru: "Иллюстрации (растра)",
    },
    {   id: 26,
        en: "Vector illustrations",
        ru: "Иллюстрации (вектор)",
    },
    {   id: 27,
        en: "3D illustrations",
        ru: "Иллюстрации (3D)",
    },
]

export const pg_Team2 = [
    {   id: 0,
        photo: "team_step.jpg",
        name_en: "Stepan A.",
        name_ru: "Степан А.",
        role_en: "Frontend developer",
        role_ru: "Фронтенд разработчик",
        skill1: 6,
        skill1_en: "JS (Vue)",
        skill1_ru: "JS (Vue)",
        skill2: 7,
        skill2_en: "HTML, CSS",
        skill2_ru: "HTML, CSS",
        skill3: 4,
        skill3_en: "Design (UX, UI)",
        skill3_ru: "Дизайн (UX, UI)",
    },
    {   id: 1,
        photo: "team_lilia.jpg",
        name_en: "Lilia D.",
        name_ru: "Лилия Д.",
        role_en: "Designer",
        role_ru: "Дизайнер",
        skill1: 7,
        skill1_en: "Design (UX, UI)",
        skill1_ru: "Дизайн (UX, UI)",
        skill2: 3,
        skill2_en: "Branding",
        skill2_ru: "Брендинг",
        skill3: 2,
        skill3_en: "Graphic",
        skill3_ru: "Графика",
    },
    {   id: 2,
        photo: "team_marat.jpg",
        name_en: "Marat D.",
        name_ru: "Марат Д",
        role_en: "Frontend & Backend dev",
        role_ru: "Фронтенд и Бэкенд",
        skill1: 7,
        skill1_en: "HTML, CSS, JS",
        skill1_ru: "HTML, CSS, JS",
        skill2: 6,
        skill2_en: "NodeJS",
        skill2_ru: "NodeJS",
        skill3: 4,
        skill3_en: "PHP",
        skill3_ru: "PHP",
    },
    {   id: 3,
        photo: "team_leo.jpg",
        name_en: "Leonid S.",
        name_ru: "Леонид С.",
        role_en: "Backend developer",
        role_ru: "Бэкенд разработчик",
        skill1: 3,
        skill1_en: "PHP",
        skill1_ru: "PHP",
        skill2: 7,
        skill2_en: "Objective-C",
        skill2_ru: "Objective-C",
        skill3: 6,
        skill3_en: "Python",
        skill3_ru: "Python",
    },
    {   id: 4,
        photo: "team_bu.jpg",
        name_en: "Kirill B.",
        name_ru: "Кирилл Б.",
        role_en: "Mobile App Dev",
        role_ru: "Мобильные приложения",
        skill1: 7,
        skill1_en: "Team lead",
        skill1_ru: "Тимлид",
        skill2: 7,
        skill2_en: "Mob App",
        skill2_ru: "Моб. прил.",
        skill3: 3,
        skill3_en: "C & Assembler",
        skill3_ru: "C и Assembler",
    },
    {   id: 5,
        photo: "team_mche.jpg",
        name_en: "Valery Ch.",
        name_ru: "Валерий Ч.",
        role_en: "Advertising & Analytics",
        role_ru: "Реклама и Аналитика",
        skill1: 7,
        skill1_en: "SMM",
        skill1_ru: "SMM",
        skill2: 6,
        skill2_en: "Advertising net's",
        skill2_ru: "Рекламные сети",
        skill3: 3,
        skill3_en: "Mailings, Spam",
        skill3_ru: "Рассылки, Спам",
    },
    {   id: 6,
        photo: "team_ak.jpg",
        name_en: "Alexandr K.",
        name_ru: "Александр К.",
        role_en: "DevOps engineer",
        role_ru: "DevOps инженер",
        skill1: 6,
        skill1_en: "Terraform",
        skill1_ru: "Terraform",
        skill2: 4,
        skill2_en: "Python, Go",
        skill2_ru: "Python, Go",
        skill3: 7,
        skill3_en: "Ansible, Docker",
        skill3_ru: "Ansible, Docker",
    },
    {   id: 7,
        photo: "team_dasha.jpg",
        name_en: "Darya V.",
        name_ru: "Дарья В.",
        role_en: "Full-stack developer",
        role_ru: "Фулстек разработчик",
        skill1: 7,
        skill1_en: "Дизайн (UX)",
        skill1_ru: "Дизайн (UX)",
        skill2: 5,
        skill2_en: "JS, HTML, CSS",
        skill2_ru: "JS, HTML, CSS",
        skill3: 4,
        skill3_en: "PHP",
        skill3_ru: "PHP",
    },
    {   id: 8,
        photo: "team_nik.jpg",
        name_en: "Nikolay T.",
        name_ru: "Николай Т.",
        role_en: "SEO specialist",
        role_ru: "SEO специалист",
        skill1: 7,
        skill1_en: "Google",
        skill1_ru: "Google",
        skill2: 7,
        skill2_en: "Yandex",
        skill2_ru: "Yandex",
        skill3: 1,
        skill3_en: "Yahoo",
        skill3_ru: "Yahoo",
    },
    {   id: 9,
        photo: "team_sz.jpg",
        name_en: "Sergey K.",
        name_ru: "Сергей К.",
        role_en: "Server Admin",
        role_ru: "Сервер Админ",
        skill1: 7,
        skill1_en: "Linux",
        skill1_ru: "Linux",
        skill2: 4,
        skill2_en: "Unix (FreeBSD)",
        skill2_ru: "Unix (FreeBSD)",
        skill3: 6,
        skill3_en: "NodeJS, PHP",
        skill3_ru: "NodeJS, PHP",
    },
    {   id: 10,
        photo: "team_svyat.jpg",
        name_en: "Svyatoslav J.",
        name_ru: "Святослав Ж.",
        role_en: "Frontend developer",
        role_ru: "Фронтенд разработчик",
        skill1: 7,
        skill1_en: "JS (Angular)",
        skill1_ru: "JS (Angular)",
        skill2: 6,
        skill2_en: "HTML, CSS",
        skill2_ru: "HTML, CSS",
        skill3: 3,
        skill3_en: "Mob App",
        skill3_ru: "Моб. прил.",
    },
    {   id: 11,
        photo: "team_doksh.jpg",
        name_en: "Ksenia D.",
        name_ru: "Ксения Д.",
        role_en: "Testing & Content",
        role_ru: "Тестирование и Контент",
        skill1: 5,
        skill1_en: "Selenium",
        skill1_ru: "Selenium",
        skill2: 7,
        skill2_en: "Appium, SoapUI",
        skill2_ru: "Appium, SoapUI",
        skill3: 2,
        skill3_en: "Design (UI)",
        skill3_ru: "Дизайн (UI)",
    },
]

export const pg_Team3 = [
    {   id: 0,
        city_en: "San Francisco, USA",
        city_ru: "Сан-Франциско, США",
        left: '4.2',
        top: '17.9',
        who: [2,1],
        main: false,
        mirror: false,
    },
    {   id: 1,
        city_en: "Toronto, Canada",
        city_ru: "Торонто, Канада",
        left: '15',
        top: '16.4',
        who: [8],
        main: false,
        mirror: false,
    },
    {   id: 2,
        city_en: "Warsaw, Poland",
        city_ru: "Варшава, Польша",
        left: '41.4',
        top: '13.4',
        who: [10],
        main: false,
        mirror: true,
    },
    {   id: 3,
        city_en: "Kyiv, Ukraine",
        city_ru: "Киев, Украина",
        left: '43.6',
        top: '14.4',
        who: [5,9],
        main: false,
        mirror: true,
    },
    {   id: 4,
        city_en: "St. Petersburg, Russia",
        city_ru: "Санкт-Петербург, Россия",
        left: '44',
        top: '10.3',
        who: [3],
        main: false,
        mirror: false,
    },
    {   id: 5,
        city_en: "Alanya, Turkey",
        city_ru: "Аланья, Турция",
        left: '44.2',
        top: '18.8',
        who: [99, 7],
        main: true,
        mirror: true,
    },
    {   id: 6,
        city_en: "Batumi, Georgia",
        city_ru: "Батуми, Грузия",
        left: '46.8',
        top: '17.2',
        who: [6],
        main: false,
        mirror: false,
    },
    {   id: 7,
        city_en: "Novosibirsk, Russia",
        city_ru: "Новосибирск, Россия",
        left: '57.6',
        top: '12.6',
        who: [0,4],
        main: false,
        mirror: false,
    },
    {   id: 8,
        city_en: "Sakhalin, Russia",
        city_ru: "Сахалин, Россия",
        left: '72.8',
        top: '15.6',
        who: [11],
        main: false,
        mirror: true,
    },
]































export const pg_Portfolio1 = [
    {   id: 0,
        en: "For",
        ru: "Для",
    },
    {   id: 1,
        en: "Similar work",
        ru: "Схожая работа",
    },
    {   id: 2,
        en: "different clients",
        ru: "разных клиентов",
    },
    {   id: 3,
        en: "from",
        ru: "от",
    },
    {   id: 4,
        en: "Back to portfolio",
        ru: "Вернуться в портфолио",
    },


    {   id: 5,
        en: "Rent ready solution",
        ru: "Арендовать готовое решение",
    },
    {   id: 6,
        en: "Buy ready solution",
        ru: "Купить готовое решение",
    },
    {   id: 7,
        en: "Analog work from scratch",
        ru: "Аналог работы с нуля",
    },
    {   id: 8,
        en: "This option is not available for operation in",
        ru: "Эта опция невозможна для работы в",
    },
    {   id: 9,
        en: "Back to portfolio",
        ru: "Вернуться в портфолио",
    },
    {   id: 10,
        en: "for",
        ru: "для",
    },
    {   id: 11,
        en: "deployment, configuration and training for free",
        ru: "развёртывание, настройка и обучение бесплатно",
    },
]

export const pg_Portfolio2 = [
    {   id: 'welcome-geo',
        name_en: "WelcomeGeo Invest",
        name_ru: "WelcomeGeo Invest",
        cat_en: "Real estate Aggregator",
        cat_ru: "Агрегатор недвижимости",
        work_en: "Turnkey website",
        work_ru: "Сайт под ключ",
        description_en: "Turnkey website for real estate catalog. Key functionality: Collection of information about real estate objects from different types of sources, Search on the map, Hot multi-level filtering, Automatic posting of new objects in social networks and on third-party real estate aggregators, Automatic updates and automatic calculations of data changing over time...",
        description_ru: "Сайт каталога недвижимости под ключ. Ключевой функционал: Сбор информации об объектах недвижимости из разных типов источников, Поиск на карте, Горячая многоуровневая фильтрация, Автоматический постинг новых объектов в соцсетях и на сторонних агрегаторах недвижимости, Автообновления и автоподсчёты данных меняющихся со временем...",

        link: "welcomegeo.com",
        url: "https://welcomegeo.com/objects?open=1",

        hours: 740,

        limit_en: "Georgia",
        limit_ru: "Грузии",

        folder: "welcome",
        pic: "welcome.jpg",
        logo: "welcome_ico.png",
        logo_style: "",
        glued: false,
        pics: [
            "welcome_1.jpg",
            "welcome_2.jpg",
            "welcome_3.jpg",
            "welcome_6.jpg",
            "welcome_5.jpg",
            "welcome_7.jpg",
        ],
    },
    {   id: "space-moving",
        name_en: "Space Moving",
        name_ru: "Space Moving",
        cat_en: "Moving Company",
        cat_ru: "Организация переездов",
        work_en: "Brand, Graphic, Site",
        work_ru: "Бренд, Графика, Сайт",
        description_en: "Brand, graphic and site for moving company",
        description_ru: "Бренд, графика и сайт для компании по организации переездов",

        link: "spacemoving.world",
        url: "https://spacemoving.world",

        hours: 140,
        limit_en: "",
        limit_ru: "",

        folder: "spacemoving",
        pic: "spacemoving.jpg",
        logo: "space_ico.png",
        logo_style: "",
        glued: false,
        pics: [
            "1.jpg",
            "2.jpg",
            "3.jpg",
            "4.png",
        ],
    },
    {   id: 'crm',
        name_en: "Kenyx Franchise",
        name_ru: "Kenyx Franchise",
        cat_en: "CRM & Constructor",
        cat_ru: "ЦРМ + Конструктор",
        work_en: "Turnkey system",
        work_ru: "Система под ключ",
        description_en: "The system is created for a group of franchise companies. Features: accounting of tasks, document flow, orders, warehouses, time, clients, automation of calculations, creation of mutually nested nomenclatures with multiple parameters. Thanks to this system, the entire group of companies was able to move away from AMO CRM and 1C services.",
        description_ru: "Система создана для группы франчайзи компаний. Возможности: учёт задач, документооборота, заказов, складов, времени, клиентов, автоматизация расчётов, создание взаимо-вложенных номенклатур с множеством параметров. Благодаря данной системе, вся группа компаний смогла уйти от сервисов AMO CRM и 1С.",

        link: "cabinet.zabor-knx.ru",
        url: "https://cabinet.zabor-knx.ru",

        hours: 530,
        limit_en: "Russia",
        limit_ru: "России",

        folder: "crm",
        pic: "crm.jpg",
        logo: "kenyx_ico.png",
        logo_style: "",
        glued: false,
        pics: [
            "crm_1.jpg",
            "crm_2.jpg",
            "crm_3.png",
            "crm_4.jpg",
            "crm_5.jpg",
            "crm_6.png",
            "crm_7.jpg",
            "crm_8.png",
        ],
    },
    {   id: "big-hog",
        name_en: "BigHog Nutrition",
        name_ru: "BigHog Nutrition",
        cat_en: "Sports Nutrition",
        cat_ru: "Спортивное питание",
        work_en: "Logo & Online shop",
        work_ru: "Лого и Интернет магазин",
        description_en: "Logo & online shop for sports nutrition",
        description_ru: "Лого и интернет магазин для спортивного питания",

        link: "",
        url: "",

        hours: 105,
        limit_en: "",
        limit_ru: "",

        folder: "bighog",
        pic: "bighog.jpg",
        logo: "bighog_ico.png",
        logo_style: "",
        glued: false,
        pics: [
            "1.jpg",
            "2.jpg",
            "3.jpg",
            "4.jpg",
            "5.jpg",
            "6.jpg",
            "7.jpg",
            "8.png",
        ],
    },
    {   id: 'interfaces-3d',
        name_en: "",
        name_ru: "",
        cat_en: "3D interfaces",
        cat_ru: "3D интерфейсы",
        work_en: "Design, Graphic",
        work_ru: "Дизайн, Графика",
        description_en: "Design & Graphic for different clients",
        description_ru: "Дизайн и Графика для разных клиентов",

        link: "",
        url: "",

        hours: 75,
        limit_en: "",
        limit_ru: "",

        folder: "3dinterfaces",
        pic: "3dinterfaces.jpg",
        logo: "3d_ico.png",
        logo_style: "",
        glued: false,
        pics: [
            "1_1.jpg",
            "1_2.jpg",
            "1_3.mp4",
            "hr",
            "3_1.jpg",
            "3_2.jpg",
            "3_3.jpg",
            "3_4.jpg",
            "hr",
            "4_1.mp4",
            "4_2.jpg",
            "4_3.mp4",
            "hr",
            "5_1.jpg",
            "5_2.jpg",
            "hr",
            "6_1.mp4",
            "6_2.jpg",
            "6_3.jpg",
        ],
    },
    {   id: 'good-boat',
        name_en: "GoodBoat",
        name_ru: "GoodBoat",
        cat_en: "Yacht rental Aggregator",
        cat_ru: "Агрегатор проката яхт",
        work_en: "Website, App and Advertising",
        work_ru: "Сайт, Приложение и Реклама",
        description_en: "Website, Application, Promotion and Advertising for a yacht rental aggregator. Unfortunately, during the coronavirus, like many others, this project went bankrupt, but we have saved all sources and materials.",
        description_ru: "Сайт, Приложение, Продвижение и Реклама для агрегатора проката яхт. К сожалению во времена коронавируса, как и многие - этот проект обанкротился, но все исходники и материалы у нас сохранены.",

        link: "",
        url: "",

        hours: 570,
        limit_en: "",
        limit_ru: "",

        folder: "goodboat",
        pic: "goodboat.jpg",
        logo: "gb_ico.png",
        logo_style: "",
        glued: true,
        pics: [
            "1.jpg",
            "2.jpg",
            "3.jpg",
            "4.jpg",
            "5.jpg",
            "6.jpg",
            "7.jpg",
            "8.jpg",
            "9.jpg",
            "10.jpg",
            "11.jpg",
            "12.jpg",
            "13.jpg",
            "14.jpg",
            "15.jpg",
            "16.jpg",
            "17.jpg",
            "18.jpg",
            "19.jpg",
            "20.jpg",
            "21.jpg",
            "22.jpg",
            "23.jpg",
            "24.jpg",
            "25.jpg",
            "26.jpg",
            "27.jpg",
            "28.jpg",
            "29.jpg",
            "30.jpg",
            "31.jpg",
        ],
    },
    {   id: 'auto3n',
        name_en: "Auto 3N",
        name_ru: "Авто 3N",
        cat_en: "Auto parts Catalog",
        cat_ru: "Каталог автозапчастей",
        work_en: "UX, UI & Frontend",
        work_ru: "UX, UI и Фронтенд",
        description_en: "UX / UI design and interface creation for auto parts catalog",
        description_ru: "UX / UI дизайн и создание интерфейса для каталога автозапчастей",

        link: "auto3n.ru",
        url: "https://auto3n.ru",

        hours: 300,
        limit_en: "",
        limit_ru: "",

        folder: "auto3n",
        pic: "auto3n.jpg",
        logo: "auto3n_ico.gif",
        logo_style: "mix-blend-mode: 'screen', filter: 'invert(1)'",
        glued: true,
        pics: [
            "0.jpg",
            "2.gif",
            "0.jpg",
            "3.jpg",
            "4.jpg",
            "5.jpg",
            "0.jpg",
            "6.jpg",
            "0.jpg",
            "7.jpg",
            "0.jpg",
            "8.jpg",
            "0.jpg",
            "9.jpg",
            "0.jpg",
            "10.jpg",
            "11.jpg",
            "12.jpg",
            "0.jpg",
            "13.jpg",
            "0.jpg",
            "14.jpg",
            "0.jpg",
            "15.jpg",
            "0.jpg",
            "16.jpg",
            "0.jpg",
            "17.jpg",
            "0.jpg",
            "18.jpg",
            "0.jpg",
            "19.jpg",
            "0.jpg",
            "20.jpg",
            "0.jpg",
            "21.jpg",
            "22.jpg",
            "23.jpg",
            "0.jpg",
            "24.jpg",
            "25.jpg",
            "26.jpg",
            "0.jpg",
            "27.jpg",
            "0.jpg",
            "28.jpg",
            "0.jpg",
            "29.jpg",
            "0.jpg",
            "30.gif",
            "0.jpg",
            "31.gif",
            "0.jpg",
            "32.jpg",
            "33.jpg",
            "34.gif",
            "35.jpg",
        ],
    },
    {   id: 'kenyx',
        name_en: "Kenyx",
        name_ru: "Kenyx",
        cat_en: "Fence & Gate factory",
        cat_ru: "Фабрика заборов и ворот",
        work_en: "Turnkey website",
        work_ru: "Сайт под ключ",
        description_en: "Turnkey website for fence & gate factory",
        description_ru: "Сайт под ключ для фабрики заборов и ворот",

        link: "zabor-knx.ru",
        url: "https://zabor-knx.ru/otkatnie",

        hours: 260,
        limit_en: "Russia",
        limit_ru: "России",

        folder: "kenyx",
        pic: "kenyx.jpg",
        logo: "kenyx_ico.png",
        logo_style: "",
        glued: true,
        pics: [
            "0_kenyx.gif",
            "0_kenyx.jpg",
            "2_kenyx.jpg",
            "1_kenyx.jpg",
            "5_kenyx.jpg",
        ],
    },
    {   id: 'fixar',
        name_en: "Fixar",
        name_ru: "Fixar",
        cat_en: "Drone Manufacturer",
        cat_ru: "Производитель дронов",
        work_en: "Site, Printing & Advertising",
        work_ru: "Cайт, Полиграфия и Реклама",
        description_en: "Website development, printing and advertising for a drone manufacturer",
        description_ru: "Разработка сайта, полиграфия и реклама для производителя дронов",

        link: "",
        url: "",

        hours: 220,
        limit_en: "",
        limit_ru: "",

        folder: "fixar",
        pic: "fixar.jpg",
        logo: "fixar_ico.png",
        logo_style: "",
        glued: false,
        pics: [
            "fixar_1.jpg",
            "fixar_2.jpg",
            "fixar_3.jpg",
            "fixar_5.jpg",
        ],
    },
    {   id: 'crossfire',
        name_en: "Crossfire",
        name_ru: "Crossfire",
        cat_en: "Weapons & Accessories",
        cat_ru: "Оружие и аксессуары",
        work_en: "Online shop",
        work_ru: "Интернет магазин",
        description_en: "Online store of weapons and ammunition",
        description_ru: "Интернет магазин оружия и амуниции",

        link: "crossfire-shop.ru",
        url: "https://crossfire-shop.ru",

        hours: 105,
        limit_en: "",
        limit_ru: "",

        folder: "crossfire",
        pic: "crossfire.jpg",
        logo: "cross_ico.png",
        logo_style: "",
        glued: false,
        pics: [
            "crossfire_1.jpg",
            "crossfire_2.jpg",
            "crossfire_3.jpg",
            "crossfire_4.jpg",
            "crossfire_5.jpg",
            "crossfire_6.jpg",
        ],
    },
    {   id: 'capperroom',
        name_en: "Capperroom",
        name_ru: "Capperroom",
        cat_en: "Sports analyst aggregator",
        cat_ru: "Агрегатор аналитиков",
        work_en: "Website, Integrations & Branding",
        work_ru: "Сайт, Интеграции и Брендинг",
        description_en: "Turnkey service and branding for a sports analyst aggregator. Key functionality allows you to make predictions on all possible outcomes of top sports events, and of course sell / buy predictions. The service automatically keeps track of the successes of Analysts in different areas, and also provides convenient infographics, filters and sorting for easy selection. To see the full/working functionality of this project, please request a link, as the combat part of the project is at the final stage of development, while pre-production is already fully operational.",
        description_ru: "Сервис под ключ и брендинг для агрегатора спортивных аналитиков. Ключевой функционал позволяет делать прогнозы на всевозможные исходы топовых спортивных событий, и конечно продавать / покупать прогнозы. Сервис автоматически ведёт учёт успехов Аналитиков в разных областях, а также предоставляет удобную инфографику, фильтры и сортировки для удобного подбора. Чтобы увидеть полный / рабочий функционал данного проекта - пожалуйста сделайте запрос на ссылку, тк боевая часть проекта находится на финальной стадии разработки, в то время как предпродакшен уже полностью дееспособен.",

        link: "capperroom.com ( *full version by request )",
        url: "https://capperroom.com/en/predictions",

        hours: 600,
        limit_en: "",
        limit_ru: "",

        folder: "capperroom",
        pic: "capperroom.jpg",
        logo: "capper_ico.png",
        logo_style: "",
        glued: false,
        pics: [
            "capperroom_0.jpg",
            "capperroom_1.jpg",
            "capperroom_2.jpg",
            "capperroom_3.jpg",
            "capperroom_4.jpg",
            "capperroom_5.gif",
            "capperroom_6.jpg",
            "capperroom_7.jpg",
            "capperroom_8.jpg",
            "capperroom_9.jpg",
            "capperroom_10.gif",
            "capperroom_11.jpg",
            "capperroom_12.jpg",
            "capperroom_13.gif",
        ],
    },
    {   id: 'logos-and-illustrations',
        name_en: "",
        name_ru: "",
        cat_en: "Logos & illustrations",
        cat_ru: "Логотипы, Иллюстрации",
        work_en: "Branding, Graphic",
        work_ru: "Брендинг, Графика",
        description_en: "Branding and Graphic for different clients",
        description_ru: "Брендинг и Графика для разных клиентов",

        link: "",
        url: "",

        hours: 8,
        limit_en: "",
        limit_ru: "",

        folder: "logos",
        pic: "logos.jpg",
        logo: "",
        logo_style: "",
        glued: false,
        pics: [
            "18.jpg",
            "hr",
            "7.jpg",
            "6.jpg",
            "hr",
            "3.jpg",
            "0.jpg",
            "1.jpg",
            "2.jpg",
            "hr",
            "4.jpg",
            "hr",
            "5.jpg",
            "hr",
            "10.jpg",
            "11.jpg",
            "hr",
            "8.png",
            "hr",
            "12.jpg",
            "13.jpg",
            "14.jpg",
            "hr",
            "15.jpg",
            "16.jpg",
            "hr",
            "19.jpg",
            "hr",
            "20.jpg",
            "21.jpg",
            "hr",
            "22.gif",
            "hr",
            "23.jpg",
            "hr",
            "24.jpg",
        ],
    },
    {   id: 'first-expert',
        name_en: "First Expert",
        name_ru: "Первый Эксперт",
        cat_en: "Legal Services",
        cat_ru: "Юридические услуги",
        work_en: "Turnkey website",
        work_ru: "Сайт под ключ",
        description_en: "Turnkey website for legal services company",
        description_ru: "Сайт под ключ для компании юридических услуг",

        link: "",
        url: "",

        hours: 90,
        limit_en: "",
        limit_ru: "",

        folder: "expert",
        pic: "expert.jpg",
        logo: "",
        logo_style: "",
        glued: false,
        pics: [
            "expert_samborsky_1.jpg",
            "expert_samborsky_2.jpg",
            "expert_samborsky_3.jpg",
            "expert_samborsky_4.jpg",
            "expert_samborsky_5.jpg",
            "expert_samborsky__6.gif",
        ],
    },
    {   id: 'proton',
        name_en: "Proton",
        name_ru: "Протон",
        cat_en: "Appliances Factory",
        cat_ru: "Завод бытовой техники",
        work_en: "Touch interface",
        work_ru: "Сенсорный интерфейс",
        description_en: "Design & development of touch interface for washing machines",
        description_ru: "Проектирование и разработка сенсорного интерфейса для стиральных машин",

        link: "",
        url: "",

        hours: 325,
        limit_en: "",
        limit_ru: "",

        folder: "washer",
        pic: "washer.jpg",
        logo: "",
        logo_style: "",
        glued: false,
        pics: [
            "washer_samborsky_2.jpg",
            "washer_samborsky_1.png",
            "washer_samborsky_3.gif",
        ],
    },
    {   id: 'world-cuisines',
        name_en: "Cuisines of World",
        name_ru: "Кухни Народов Мира",
        cat_en: "Culinary Catalog",
        cat_ru: "Кулинарный каталог",
        work_en: "Mobile app",
        work_ru: "Мобильное приложение",
        description_en: "Mobile app of culinary catalog",
        description_ru: "Мобильное приложение кулинарного каталога",

        link: "",
        url: "",

        hours: 230,
        limit_en: "",
        limit_ru: "",

        folder: "kitchens",
        pic: "kitchens.jpg",
        logo: "",
        logo_style: "",
        glued: false,
        pics: [
            "kitchens_samborsky_1.png",
            "kitchens_samborsky_2.png",
        ],
        pics_mob: [
            "kitchens_samborsky_1_mob.png",
            "kitchens_samborsky_2_mob.png",
        ],
    },
    {   id: 'your-style',
        name_en: "Your Style",
        name_ru: "Твой Стиль",
        cat_en: "Conditioning Systems",
        cat_ru: "Системы охлаждения",
        work_en: "UX, UI & Frontend",
        work_ru: "UX, UI и Frontend",
        description_en: "UX, UI and Frontend developed for cooling and ventilation systems",
        description_ru: "Разработаны UX, UI и Frontend для систем охлаждения и вентилирования",

        link: "climat-ts.ru",
        url: "https://climat-ts.ru",

        hours: 140,
        limit_en: "",
        limit_ru: "",

        folder: "conditioner",
        pic: "conditioner.jpg",
        logo: "",
        logo_style: "",
        glued: true,
        pics: [
            "conditioner_samborsky_1.jpg",
            "conditioner_samborsky_2.jpg",
            "conditioner_samborsky_3.jpg",
            "conditioner_samborsky_4.jpg",
            "conditioner_samborsky_5.jpg",
            "greyline.jpg",
            "conditioner_samborsky_6.gif",
            "greyline.jpg",
            "conditioner_samborsky_7.jpg",
            "greyline.jpg",
            "conditioner_samborsky_8.jpg",
            "greyline.jpg",
        ],
    },
    {   id: 'assorted-works',
        name_en: "",
        name_ru: "",
        cat_en: "Assorted works",
        cat_ru: "Ассорти работ",
        work_en: "Sites & Apps",
        work_ru: "Сайты и Приложения",
        description_en: "Websites and Applications for different clients",
        description_ru: "Сайты и Приложения для разных клиентов",

        link: "",
        url: "",

        hours: 13,
        limit_en: "",
        limit_ru: "",

        folder: "sites",
        pic: "sites.jpg",
        logo: "",
        logo_style: "",
        glued: false,
        pics: [
            "4.jpg",
            "hr",
            "1.jpg",
            "hr",
            "8.jpg",
            "hr",
            "3.jpg",
            "hr",
            "7.gif",
            "hr",
            "2.jpg",
            "hr",
            "5_2.jpg",
            "5_3.jpg",
            "5_1.gif",
            "hr",
            "11.jpg",
            "hr",
            "6.jpg",
            "hr",
            "12.jpg",
            "hr",
            "10.jpg",
            "hr",
            "13_1.jpg",
            "13_2.gif",
            "13_3.gif",
            "13_4.jpg",
        ],
    },
]































import React, {useContext, useEffect, useRef} from 'react';
import {AuthContext} from '../context/Context.js'
import {basicText} from "../context/BasicInterfaceText"
import img1 from '../img/i_ocenka.svg'
import img21 from '../img/i_zarabotat.svg'
import img22 from '../img/i_zarabotat_en.svg'
import img3 from '../img/i_skidka.svg'
import img41 from '../img/i_oplatit.svg'
import img42 from '../img/i_oplatit_en.svg'
import img5 from '../img/i_mail.svg'
import wa from '../img/whatsapp.jpg'
import tg from '../img/telegram.jpg'
import {changeLang} from "../functions/changeLang";

const TopMenu = () => {
    const {lang, setLang, isLandscape, isLaptop, setPops} = useContext(AuthContext)

    // refs
    const topLng1 = useRef()
    const topLng2 = useRef()
    const topPop1 = useRef()
    const topPop2 = useRef()
    const topPop3 = useRef()
    const topPop4 = useRef()
    const topPop5 = useRef()
    const topMess1 = useRef()
    const topMess2 = useRef()
    const topPhone = useRef()

    // loading
    useEffect(() => {
        let t1 = "1.4vw"
        let t2 = "1.5vw"
        let t3 = "1.45vw"
        if(isLaptop){
            t1 = "1.6vw"
            t2 = "1.7vw"
            t3 = "1.65vw"
        }

        if(isLandscape){
            setTimeout(function (){
                topLng1.current.style.opacity = '0.3'
                topLng1.current.style.marginTop = t2
                setTimeout(function (){
                    topLng2.current.style.opacity = '0.3'
                    topLng2.current.style.marginTop = t2
                }, 300)
                setTimeout(function (){
                    topLng1.current.style.animationDuration = '0s'
                    topLng2.current.style.animationDuration = '0s'
                }, 1500)
            }, 1500) // выдвигаем флаги
            setTimeout(function (){
                topPop1.current.style.opacity = '1'
                topPop1.current.style.paddingTop = t1
                setTimeout(function (){
                    topPop2.current.style.opacity = '1'
                    topPop2.current.style.paddingTop = t1
                }, 300)
                setTimeout(function (){
                    topPop3.current.style.opacity = '1'
                    topPop3.current.style.paddingTop = t1
                }, 600)
                setTimeout(function (){
                    topPop4.current.style.opacity = '1'
                    topPop4.current.style.paddingTop = t1
                }, 900)
                setTimeout(function (){
                    topPop5.current.style.opacity = '1'
                    topPop5.current.style.paddingTop = t1
                }, 1200)
            }, 2100) // выдвигаем pops
            setTimeout(function (){
                topMess1.current.firstElementChild.style.opacity = '0.4'
                topMess1.current.firstElementChild.style.marginTop = t2
                setTimeout(function (){
                    topMess2.current.firstElementChild.style.opacity = '0.4'
                    topMess2.current.firstElementChild.style.marginTop = t2
                }, 300)
                setTimeout(function (){
                    topPhone.current.style.opacity = '1'
                    topPhone.current.style.paddingTop = t3
                }, 600)
            }, 3600) // выдвигаем контакты
        }
    }, [isLandscape, isLaptop])

    return (
        <div>
            {isLandscape ?
            <div className="topmenu">
                <div className="topmenu-langs">
                    <div ref={topLng1}
                        className={`topmenu-langs-flag topmenu-langs-flag-ru ${lang === 'ru' ? ' active' : ''}`}
                        onClick={() => changeLang('ru', lang, setLang)}
                    > </div>
                    <div ref={topLng2}
                        className={`topmenu-langs-flag topmenu-langs-flag-en ${lang === 'en' ? ' active' : ''}`}
                        onClick={() => changeLang('en', lang, setLang)}
                    > </div>
                </div>
                <div className="topmenu-pops">
                    <div className="topmenu-pops-item" onClick={() => setPops({
                            p0: true,
                            p1: true,
                            p2: false,
                            p3: false,
                            p4: false,
                            p5: false,
                            p6: false,
                            p7: false,
                            p8: false,
                            p9: false,
                        })} ref={topPop1}>
                        <img src={img1} alt=""/>{basicText[1][lang]}
                    </div>
                    <div className="topmenu-pops-item" onClick={() => setPops({
                            p0: true,
                            p1: false,
                            p2: true,
                            p3: false,
                            p4: false,
                            p5: false,
                            p6: false,
                            p7: false,
                            p8: false,
                            p9: false,
                        })} ref={topPop2}>
                        <img src={lang === 'en' ? img22 : img21} alt=""/>{basicText[2][lang]}
                    </div>
                    <div className="topmenu-pops-item topmenu-pops-discount" onClick={() => setPops({
                            p0: true,
                            p1: false,
                            p2: false,
                            p3: true,
                            p4: false,
                            p5: false,
                            p6: false,
                            p7: false,
                            p8: false,
                            p9: false,
                        })} ref={topPop3}>
                        <img src={img3} alt=""/>{basicText[3][lang]}
                    </div>
                    <div className="topmenu-pops-item" onClick={() => setPops({
                            p0: true,
                            p1: false,
                            p2: false,
                            p3: false,
                            p4: true,
                            p5: false,
                            p6: false,
                            p7: false,
                            p8: false,
                            p9: false,
                        })} ref={topPop4}>
                        <img src={lang === 'en' ? img42 : img41} alt=""/>{basicText[4][lang]}
                    </div>
                    <div className="topmenu-pops-item" onClick={() => setPops({
                            p0: true,
                            p1: false,
                            p2: false,
                            p3: false,
                            p4: false,
                            p5: true,
                            p6: false,
                            p7: false,
                            p8: false,
                            p9: false,
                        })} ref={topPop5}>
                        <img src={img5} alt=""/>{basicText[5][lang]}
                    </div>
                </div>
                <div className="topmenu-contacts">
                    <a href={"https://wa.me/+" + basicText[6]['data']} target="_blank" rel="noreferrer" className="topmenu-contacts-mess"  ref={topMess1}>
                        <img src={wa} alt=""/>
                    </a>
                    <a href={"https://t.me/" + basicText[7]['data']} target="_blank" rel="noreferrer" className="topmenu-contacts-mess"  ref={topMess2}>
                        <img src={tg} alt=""/>
                    </a>
                    <a href={"tel:+" + basicText[8]['data']} className="topmenu-contacts-phone"  ref={topPhone}>{"+" + basicText[8]['data']}</a>
                </div>
            </div>
            :''}
        </div>
    );
};

export default TopMenu;